// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { Environment } from './environment.interface';

export const environment: Environment = {
  "stage": "prod",
  "host": "nodelibrary",
  "app": "node-library",
  "numResponses": 1,
  "header": "f5 node library",
  "maxResponses": 1,
  "archetype": "blog",
  "title": "f5 node library",
  "metaTitle": "f5 node library",
  "metaDescription": "official node library for apps powered by f5"
};